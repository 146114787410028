/* Global Styles */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  color: #bbbfba;
}

/* Container for the entire app */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header styles with fade-in animation */
.header {
  text-align: center;
  padding: 20px 0;
  animation: fadeIn 1s ease-in-out;
}

.header h1 {
  margin: 0;
}

/* Main content styles with fade-in animation */
.main-content {
  flex: 1;
  text-align: center;
  padding: 20px 0;
  animation: fadeIn 1.2s ease-in-out;
}

.intro {
  font-size: 80%;
  margin-bottom: 20px;
}

/* Contact Form Styles */
.contact-form-container {
  margin: 20px auto;
  max-width: 600px;
  padding: 0 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  resize: vertical;
  transition: border-color 0.3s ease;
}

.contact-textarea:focus {
  border-color: #5089aa;
}

.contact-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #333;
  color: #5089aa;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-button:hover {
  background-color: #555;
  transform: scale(1.05);
}

.feedback {
  margin-top: 10px;
  font-size: 0.9em;
}

/* SEO Text (hidden by design) */
.seo-text {
  color: #1e1e1e;
}

.seo-text p {
  font-size: 1%;
  margin: 0.2em 0;
}

/* Footer styles with slide-up animation */
.footer {
  text-align: center;
  padding: 20px 0;
  background: #1e1e1e;
  /* border-top: 1px solid #333; */
  animation: slideUp 1s ease-in-out;
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Container to center the search bar */
.search-container {
  display: flex;
  justify-content: center;
  margin: 75px 0;
  position:relative;
  align-content: center;

}

/* Search form styling */
.search-form {
  display: flex;
  align-items: center;
}

/* Styling for the text input */
.search-input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  width: 300px;
}

/* Styling for the submit button */
.search-button {
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  background-color: #333;
  color: #5089aa;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #555;
}
